<template>
    <div>
        <div class="relative bg-gray-50 pb-20 px-4 sm:px-6 lg:pb-28 lg:px-8">
            <div class="absolute inset-0">
                <div class="bg-white h-1/3 sm:h-2/3"></div>
            </div>
            <div class="relative max-w-7xl mx-auto">
                <div class="text-center">
                    <h2 class="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
                        Guides from Tokengen
                    </h2>
                    <p class="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
                        Below you'll find guides written by the tokengen team. We have guides from starting your token,
                        to adding liquidity and locking it.
                    </p>
                </div>

                <div class="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
                    <div class="flex flex-col rounded-lg shadow-lg overflow-hidden" v-for="guide in guides">
                        <div class="flex-shrink-0">
                            <img class="h-48 w-full object-cover"
                                 :src="guide.data.imageSrc" alt="">
                        </div>
                        <div class="flex-1 bg-white p-6 flex flex-col justify-between">
                            <div class="flex-1">
                                <p class="text-sm font-medium text-indigo-600">
                                    <router-link :to="{name:guide.data.pathName}" class="mt-2 hover:underline">
                                        guide
                                    </router-link>
                                </p>
                                <router-link :to="{name:guide.data.pathName}" class="mt-2">
                                    <p class="text-xl font-semibold text-gray-900">
                                        {{ guide.data.name }}
                                    </p>
                                    <p class="mt-3 text-base text-gray-500" v-html="guide.data.description"></p>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import { guides } from '../router';

export default {
    name: 'Guides',
    data() {
        return {
            guides,
        };
    },
};
</script>

<style scoped></style>
